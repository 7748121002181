import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import styled from "styled-components"
import SEO from "../components/SEO"
import { MDXRenderer } from "gatsby-plugin-mdx"

export const query = graphql`
  query getQuotes {
    quotes: allFile(
      filter: { sourceInstanceName: { eq: "quotes" }, extension: { eq: "mdx" } }
      sort: { fields: modifiedTime, order: DESC }
    ) {
      edges {
        node {
          childMdx {
            frontmatter {
              by
              source
              link
            }
            body
          }
          modifiedTime
          name
        }
      }
    }
  }
`
const Quotes = ({ data }) => {
  return (
    <Layout>
      <SEO title="Quotes" />
      <Wrapper>
        <div className="content">
          <h1>Quotes</h1>
          {data.quotes.edges.map(({ node }, index) => {
            return (
              <div
                id={node.name}
                key={index}
                className="quote"
                data-author={node.childMdx.frontmatter.by}
              >
                <div className="source">
                  <div className="quote-header">
                    <h3>{node.childMdx.frontmatter.by}</h3>
                    <a
                      href={node.childMdx.frontmatter.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {node.childMdx.frontmatter.source}
                    </a>
                  </div>
                  <a
                    href={node.childMdx.frontmatter.link}
                    className="quote-body"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MDXRenderer>{node.childMdx.body}</MDXRenderer>
                  </a>
                </div>
                <hr />
              </div>
            )
          })}
        </div>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  .content {
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .quote-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 1em;

    h3 {
      margin: 0;
      padding: 0;
    }

    a {
      padding-left: 1em;
      font-style: italic;
      color: #aaa;
    }
  }

  .quote-body {
    color: var(--mainBlack);
    text-decoration: none;
  }

  .quote {
    padding-top: 10px;
  }

  .source {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 500px) {
    .content {
      padding: 0 1em;
    }

    .quote-header {
      display: flex;
      flex-direction: column;
    }
  }
`

export default Quotes
